import React, { useCallback } from 'react';
import './Team.css';

const images = [
  { id: 1, src: '/onunu11.png', title: 'Join The Team' },
  { id: 2, src: '/onunu12.png', title: 'Be A Vendor' },
  { id: 3, src: '/onunu8.jpg', title: 'Be A Sponsor' },
];

const Team = () => {
  // Memoize the click handler using useCallback
  const handleImageClick = useCallback((image) => {
    alert(`You clicked on: ${image.title}`);
  }, []);

  const handleButtonClick = (image) => {
    alert(`You clicked the button for: ${image.title}`);
  };

  return (
    <div className="team-wrapper">
    
      <div className="team-container">
        {images.map((image) => (
          <div key={image.id} className="team-item">
            <img
              src={image.src}
              alt={image.title}
              className="team-image"
              onClick={() => handleImageClick(image)}
            />
            <button
              className="image-button"
              onClick={() => handleButtonClick(image)}
            >
               {image.title}
            </button>
          </div>
        ))}
      </div>
      <div className="info-text">
        <p>
          Onunu Festival is a product of strong desire and zeal to spread the culture,tradition and most importantly, one of the foods that showcases the Rivers culture.
        </p>
       
        <img src="/vect.png" alt="Vector Icon" className="info-vector" />
      </div>
    </div>
  );
};

export default Team;
