import React, { useState, useEffect, useRef } from 'react';
import './Header.css';


const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <header className="header">
      <div className="header-right">
        <a href="#home" className="header-link">Home</a>
        <a href="#about" className="header-link">About Us</a>
        <a href="#experiences" className="header-link">Experiences</a>
      </div>

      <div className="header-center">
        <img src="/logo.png" alt="Logo" className="logo" />
      </div>

      <img
        src="/hamburger.png"  
        alt="Menu"
        className="hamburger"
        onClick={toggleMenu}
      />

      <div className="header-left">
        <a href="#contact" className="header-link">Contact Us</a>
        <a href="#sponsors" className="header-link">Sponsors</a>
        <button className="buy-tickets">Buy Tickets</button>
      </div>

      {isMenuOpen && (
        <div className="mobile-menu" ref={menuRef}>
          <a href="#home" className="header-link">Home</a>
          <a href="#about" className="header-link">About Us</a>
          <a href="#experiences" className="header-link">Experiences</a>
          <a href="#contact" className="header-link">Contact Us</a>
          <a href="#sponsors" className="header-link">Sponsors</a>
          <button className="buy-tickets">Buy Tickets</button>
        </div>
      )}
    </header>
  );
};

export default Header;
