import React, { useCallback } from 'react';
import './Gallery.css';

const images = [
  { id: 1, src: '/about1.png', title: 'Image 1' },
  { id: 2, src: '/about2.png', title: 'Image 2' },
  { id: 3, src: '/about3.png', title: 'Image 3' },
  { id: 4, src: '/about4.png', title: 'Image 4' },
];

const Gallery = () => {
  // Memoize the click handler using useCallback
  const handleImageClick = useCallback((image) => {
    alert(`You clicked on: ${image.title}`);
  }, []);

  return (
    <div className="gallery-wrapper">
      <div className="header-container">
        <h1>About </h1>
        <img src="/Vector.png" alt="Vector Icon" className="header-vector" />
      </div>
      <div className="gallery-container">
        {images.map((image) => (
          <div 
            key={image.id} 
            className="gallery-item"
            onClick={() => handleImageClick(image)}
          >
            <img src={image.src} alt={image.title} className="gallery-image" />
          </div>
        ))}
      </div>
      <div className="about-text">
        <p>
          Onunu festival is a platform that helps in promoting our rivers cultural 
          heritage and culture. A way to connect, have fun, and enhance social life.
        </p>
        <button className="about-button">About OnunuFestival</button>
        <img src="/vect.png" alt="Vector Icon" className="about-vector" />
      </div>
    </div>
  );
};

export default Gallery;
