import React from 'react';
import './About.css';

const images = [
  { id: 1, src: '/onunu1.jpeg', alt: 'Image 1' }, 
  { id: 2, src: '/onunu6.jpg', alt: 'Image 2' },
  { id: 3, src: '/onunu4.jpg', alt: 'Image 3' },
  { id: 4, src: '/onunu5.jpg', alt: 'Image 4' },
];

const About = () => {
  return (
    <div className="about-container">
      {images.map((image, index) => (
        <div key={image.id} className={`about-item about-item-${index + 1}`}>
          <img src={image.src} alt={image.alt} />
        </div>
      ))}
    </div>
  );
};

export default About;
