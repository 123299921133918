import React from 'react';
import Header from './components/Header'; // Importing the Header component
import Home from './components/Home';
import Gallery from './components/Gallery';
import About from './components/About';
import Team from './components/Team';
import Footer from './components/Footer'

const App = () => {
  return (
    <div>  
      <Header /> 
      <Home /> {/* Integrating the Header component */}
      <Gallery /> 
      <About />
      <Team />    
      <Footer />
      
    </div>
  );
}

export default App;
